import React, { FC, useState, useEffect, Suspense, lazy } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import NavBar from "./components/formikInputs/NavBar.tsx";
import Loader from "./components/formikInputs/Loader.tsx";
import "./index.css";
import ScrollToTop from "./components/formikInputs/ScrollToTop.tsx";
import Footer from "./components/formikInputs/Footer.tsx";
import { ThemeProvider, createTheme } from "@mui/material";

const Home = lazy(() => import("./pages/Home.tsx"));
const AboutPage = lazy(() => import("./pages/AboutPage.tsx"));
const Team = lazy(() => import("./pages/Team.tsx"));
const Event = lazy(() => import("./pages/EventsPage.tsx"));
const FAQs = lazy(() => import("./pages/FAQs.tsx"));
const Community = lazy(() => import("./pages/Community.tsx"));

const Placeholder = () => (
  <div style={{ padding: "20px", textAlign: "center" }}>
    <div style={{ backgroundColor: "#e0e0e0", height: "200px", marginBottom: "10px" }}></div>
    <div style={{ backgroundColor: "#e0e0e0", height: "20px", width: "80%", margin: "10px auto" }}></div>
    <div style={{ backgroundColor: "#e0e0e0", height: "20px", width: "60%", margin: "10px auto" }}></div>
  </div>
);

const App: FC = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // This ensures the loader shows every time the site is opened or a page is reloaded
    const timer = setTimeout(() => setLoading(false), 3000); // 3-second delay before showing the main content
    return () => clearTimeout(timer); // Clean up the timer if the component unmounts
  }, []); // Empty dependency array to run only on initial load and reload

  const theme = createTheme({
    palette: {
      primary: {
        main: "#ffffff",
        contrastText: "#000000",
      },
      secondary: {
        main: "#4285f4",
        contrastText: "#ffffff",
      },
      text: {
        primary: "#000000",
      },
      error: {
        main: "#EF5D54",
      },
      success: {
        main: "#3EAA76",
      },
      info: {
        main: "#6F757B",
        contrastText: "#5454EF",
      },
    },
    typography: {
      fontFamily: "Product Sans, Inter, Arial, sans-serif",
      h1: {
        fontFamily: "Product Sans, Arial, sans-serif",
        fontWeight: 600,
        fontSize: "48px",
        lineHeight: "110%",
      },
      h2: {
        fontFamily: "Product Sans, Arial, sans-serif",
        fontWeight: 600,
        fontSize: "38px",
        lineHeight: "100%",
      },
      h3: {
        fontFamily: "Product Sans, Arial, sans-serif",
        fontWeight: 600,
        fontSize: "28px",
        lineHeight: "95%",
      },
      h4: {
        fontFamily: "Product Sans, Arial, sans-serif",
        fontWeight: 600,
        fontSize: "18px",
        lineHeight: "95%",
      },
      h5: {
        fontFamily: "Product Sans, Arial, sans-serif",
        fontWeight: 600,
        fontSize: "8px",
        lineHeight: "90%",
      },
    },
    components: {
      MuiTypography: {
        styleOverrides: {
          h2: `
          @media only screen and (max-width: 500px) {
            font-size: 45px;
          }
          `,
        },
      },
      MuiButton: {
        styleOverrides: {
          contained: {
            boxShadow: "none",
          },
          sizeSmall: `
            padding-top: 12px;
            padding-bottom: 12px;
            padding-left: 24px;
            padding-right: 24px;
            font-size: 12px;
            width: 245px;
            text-transform: none;
            font-family: Product Sans, Inter, sans-serif;
            font-weight: 600;
            line-height: 150%;
            word-wrap: break-word;
          `,
          sizeMedium: `
            padding-top: 16px;
            padding-bottom: 16px;
            padding-left: 32px;
            padding-right: 32px;
            font-size: 14px;
            text-transform: none;
            font-family: Product Sans, Inter, sans-serif;
            font-weight: 600;
            line-height: 150%;
            word-wrap: break-word;
          `,
          sizeLarge: `
            padding-top: 20px;
            padding-bottom: 20px;
            padding-left: 24px;
            padding-right: 24px;
            font-size: 16px;
            width: 279px;
            text-transform: none;
            font-family: Product Sans, Inter, sans-serif;
            font-weight: 600;
            line-height: 150%;
            word-wrap: break-word;
          `,
        },
      },
    },
  });

  return (
    <Router>
      <ScrollToTop/>
      <div className="App">
        <ThemeProvider theme={theme}>
          {loading ? (
            <Loader /> // Show the loader while loading is true
          ) : (
            <>
              <NavBar />
              <Suspense fallback={<Placeholder />}>
                <Routes>
                  <Route path="/" element={<Home />} />
                  <Route path="/about" element={<AboutPage />} />
                  <Route path="/team" element={<Team />} />
                  <Route path="/events" element={<Event />} />
                  <Route path="/FAQs" element={<FAQs />} />
                  <Route path="/community" element={<Community />} />
                </Routes>
              </Suspense>
              <div style={{ backgroundColor: "#F9F9FF", marginTop: "10vh" }}>
                <Footer />
              </div>
            </>
          )}
        </ThemeProvider>
      </div>
    </Router>
  );
};

export default App;