import React from 'react';
import { Box, IconButton, Link as ALink,createSvgIcon } from '@mui/material';

const SocialMedia = () => {
  const TikTokIcon = createSvgIcon(
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 347.67 398.67">
    <path d="M295.74,79.91c-21.48-14-36.97-36.41-41.81-62.52-1.04-5.64-1.62-11.45-1.62-17.39h-68.54l-.11,274.68c-1.15,30.76-26.47,55.45-57.5,55.45-9.65,0-18.73-2.41-26.73-6.62-18.34-9.65-30.89-28.87-30.89-50.99,0-31.77,25.85-57.62,57.61-57.62,5.93,0,11.62,.98,17,2.66v-69.97c-5.57-.76-11.23-1.23-17-1.23C56.59,146.36,0,202.95,0,272.52c0,42.68,21.32,80.45,53.86,103.29,20.49,14.39,45.42,22.86,72.3,22.86,69.56,0,126.15-56.59,126.15-126.15V133.23c26.88,19.29,59.82,30.66,95.36,30.66V95.35c-19.14,0-36.97-5.69-51.93-15.44Z" />
  </svg>,
  'TikTok');
  return (
    <Box>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <ALink href="https://www.tiktok.com/@gdsc.tmu?_t=8l5peynvekq&_r=1" target="_blank" rel="noopener noreferrer">
          <IconButton aria-label="TikTok" sx={{ marginRight: '8px' }}>
           {/* <TikTokIcon style={{fontSize: "20px"}}/> */}
           <img src='TikTok-Social-Icon-Color-Black.svg' alt='TikTok' style={{width: '24px', height: '24px'}} />
          </IconButton>
        </ALink>
        <ALink href="https://www.linkedin.com/company/gdgtmu/posts/?feedView=all" target="_blank" rel="noopener noreferrer">
          <IconButton aria-label="LinkedIn" sx={{ marginRight: '8px' }}>
            <img src="/In-Blue-128.png" alt="LinkedIn" style={{ width: '24px', height: '24px' }} />
          </IconButton>
        </ALink>
        <ALink href="https://discord.com/invite/qurQXyCdrW" target="_blank" rel="noopener noreferrer">
          <IconButton aria-label="Discord" sx={{ marginRight: '8px' }}>
            <img src="/discord-mark-black2.svg" alt="Discord" style={{ width: '24px', height: '24px' }} />
          </IconButton>
        </ALink>
        <ALink href="https://www.instagram.com/gdgtmu/" target="_blank" rel="noopener noreferrer">
          <IconButton aria-label="Instagram">
            <img src="/Instagram_Glyph_Gradient.svg" alt="Instagram" style={{ width: '24px', height: '24px' }} />
          </IconButton>
        </ALink>
      </Box>
    </Box>
  );
};

export default SocialMedia;