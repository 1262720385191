import React from 'react';
import { Box, Typography, IconButton, Link as ALink, createSvgIcon} from '@mui/material';
import LinkedIn from '@mui/icons-material/LinkedIn';
import InstagramIcon from '@mui/icons-material/Instagram';
import SocialMedia from './SocialMedia.tsx';

const Footer = () => {
  const DiscordIcon = createSvgIcon(
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 127.14 96.36"><path d="M107.7,8.07A105.15,105.15,0,0,0,81.47,0a72.06,72.06,0,0,0-3.36,6.83A97.68,97.68,0,0,0,49,6.83,72.37,72.37,0,0,0,45.64,0,105.89,105.89,0,0,0,19.39,8.09C2.79,32.65-1.71,56.6.54,80.21h0A105.73,105.73,0,0,0,32.71,96.36,77.7,77.7,0,0,0,39.6,85.25a68.42,68.42,0,0,1-10.85-5.18c.91-.66,1.8-1.34,2.66-2a75.57,75.57,0,0,0,64.32,0c.87.71,1.76,1.39,2.66,2a68.68,68.68,0,0,1-10.87,5.19,77,77,0,0,0,6.89,11.1A105.25,105.25,0,0,0,126.6,80.22h0C129.24,52.84,122.09,29.11,107.7,8.07ZM42.45,65.69C36.18,65.69,31,60,31,53s5-12.74,11.43-12.74S54,46,53.89,53,48.84,65.69,42.45,65.69Zm42.24,0C78.41,65.69,73.25,60,73.25,53s5-12.74,11.44-12.74S96.23,46,96.12,53,91.08,65.69,84.69,65.69Z"/></svg>, 'discord');
  const TikTokIcon = createSvgIcon(
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 347.67 398.67">
    <path d="M295.74,79.91c-21.48-14-36.97-36.41-41.81-62.52-1.04-5.64-1.62-11.45-1.62-17.39h-68.54l-.11,274.68c-1.15,30.76-26.47,55.45-57.5,55.45-9.65,0-18.73-2.41-26.73-6.62-18.34-9.65-30.89-28.87-30.89-50.99,0-31.77,25.85-57.62,57.61-57.62,5.93,0,11.62,.98,17,2.66v-69.97c-5.57-.76-11.23-1.23-17-1.23C56.59,146.36,0,202.95,0,272.52c0,42.68,21.32,80.45,53.86,103.29,20.49,14.39,45.42,22.86,72.3,22.86,69.56,0,126.15-56.59,126.15-126.15V133.23c26.88,19.29,59.82,30.66,95.36,30.66V95.35c-19.14,0-36.97-5.69-51.93-15.44Z" />
  </svg>,
  'TikTok');
  return (
    <Box
      sx={{
        width: '80vw',
        backgroundColor: '#F9F9FF',
        padding: '20px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        maxWidth: '80vw',
        flexDirection: {xs: 'column', md: 'row'},
        margin: '0 auto',
        textAlign: 'center'
      }}
    >
      <Typography
        className="text-reg .font-sm-10"
        color="primary.contrastText"
        sx={{ fontFamily: 'DM Sans', fontWeight: 400 }}
      >
        Copyright © 2024 GDSC All Rights Reserved
      </Typography>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <SocialMedia/>
      </Box>
    </Box>
  );
};

export default Footer;